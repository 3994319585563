import { fetchDistributors } from '@/api/distributor/distributor'
import { fetchDictionaries, fetchSiteAssets, fetchSiteItemRules, fetchSiteItems, fetchSitePages, fetchSiteSections, fetchSites } from '@/api/site/site'
import { useNotifyErrors } from '@/composables'
import { computed, ref } from '@vue/composition-api'

const sitesList = ref([]) // globally shared.

export default () => {
  // Site Info
  const loadSites = async () =>
    !sitesList.length &&
    fetchSites({ take: 999 })
      .then(({ data }) => {
        sitesList.value = data.data.records
      })
      .catch(useNotifyErrors)

  const siteOptions = computed(() =>
    sitesList.value.map(s => ({
      title: s.nicename,
      value: s.id,
    })),
  )

  // Dictionaries
  const dictionariesList = ref([])
  const loadDictionaries = () =>
    fetchDictionaries({ take: 999, root_only: true })
      .then(({ data }) => {
        dictionariesList.value = data.data.records
      })
      .catch(useNotifyErrors)

  // Site Assets
  const siteAssetsList = ref([])
  const loadSiteAssets = () =>
    fetchSiteAssets({ take: 999 })
      .then(({ data }) => {
        siteAssetsList.value = data.data.records
      })
      .catch(useNotifyErrors)

  // Site Items
  const siteItemsList = ref([])
  const loadSiteItems = query =>
    fetchSiteItems({ ...query, take: 999 })
      .then(({ data }) => {
        siteItemsList.value = data.data.records
      })
      .catch(useNotifyErrors)

  // Site Items
  const siteItemRulesList = ref(null)
  const loadSiteItemRules = () =>
    fetchSiteItemRules({ take: 999 })
      .then(({ data }) => {
        siteItemRulesList.value = data.data
      })
      .catch(useNotifyErrors)

  // Site Sections
  const siteSectionsList = ref([])
  const loadSiteSections = query =>
    fetchSiteSections({ ...query, take: 999 })
      .then(({ data }) => {
        siteSectionsList.value = data.data.records
      })
      .catch(useNotifyErrors)

  // Site Pages
  const sitePagesList = ref([])
  const loadSitePages = async () =>
    fetchSitePages({ take: 999 })
      .then(({ data }) => {
        sitePagesList.value = data.data.records
        console.log('data.data.records', data.data.records)
      })
      .catch(useNotifyErrors)

  // fetchDistributors
  const distributorList = ref([])
  const loadDistributors = async () =>
    fetchDistributors({ take: 999 })
      .then(({ data }) => {
        distributorList.value = data.data.records
      })
      .catch(useNotifyErrors)

  return {
    sitesList,
    loadSites,
    siteOptions,
    dictionariesList,
    loadDictionaries,
    siteAssetsList,
    loadSiteAssets,
    siteItemsList,
    loadSiteItems,
    siteSectionsList,
    loadSiteSections,
    sitePagesList,
    loadSitePages,
    siteItemRulesList,
    loadSiteItemRules,
    distributorList,
    loadDistributors,
  }
}
