<template>
  <div id="site-pages-list">
    <v-breadcrumbs
      divider="/"
      large
      :items="breadcrumbs"
    ></v-breadcrumbs>
    <v-card
      v-show="false"
      class="filter-section"
    >
      <v-row class="">
        <v-col cols="6">
          <v-card-title class="font-weight-bold ">
            {{ $t('common.list.filters') }}
          </v-card-title>
        </v-col>
        <v-col
          cols="6"
          class="d-flex justify-end"
        >
          <v-btn
            icon
            @click="showFilter = !showFilter"
          >
            <v-icon>{{ showFilter ? icons.mdiMenuUp : icons.mdiMenuDown }}</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-expand-transition>
        <v-row
          v-show="showFilter"
          class="px-2 ma-0"
        >
          <!-- role filter -->

          <v-col
            cols="12"
            sm="3"
          >
            <v-select
              v-model="filters.site_id"
              :placeholder="$t('common.list.selectSite')"
              :items="sitesList"
              item-text="url"
              item-value="id"
              single-line
              dense
              outlined
              clearable
              hide-details
            ></v-select>
          </v-col>
        </v-row>
      </v-expand-transition>
    </v-card>
    <v-row class="my-0 d-flex justify-end">
      <v-col
        cols="12"
        sm="2"
        style="margin-right:auto"
        align="right"
      >
        <v-btn
          color="primary"
          block
          class=""
          :loading="loading"
          :disabled="loading"
          @click="loadData"
        >
          <v-icon class="prefix-icon">
            {{ icons.mdiRefresh }}
          </v-icon>
          <span>
            {{ $t('common.list.refresh') }}
          </span>
        </v-btn>
      </v-col>
      <v-col
        cols="12"
        sm="2"
        align="right"
      >
        <!-- <v-btn depressed block color="success" @click="setAside(null)">
          <v-icon class="prefix-icon">{{ icons.mdiPlus }}</v-icon>
          {{$t('common.list.create')}}
        </v-btn> -->
      </v-col>
    </v-row>

    <v-card>
      <!-- table -->
      <v-data-table
        :headers="tableColumns"
        :items="tableItems"
        :options.sync="options"
        :server-items-length="tableTotal"
        :loading="loading"
        class="text-no-wrap"
        :header-props="headerprops"
      >
        <!-- name -->
        <template #[`item.name`]="{ item }">
          <span class="text-no-wrap">{{ t(item.name) }}</span>
        </template>

        <!-- notes -->
        <template #[`item.notes`]="{ item }">
          <span class="text-wrap">{{ item.notes }}</span>
        </template>

        <!-- href -->
        <template #[`item.href`]="{ item }">
          <router-link
            class="font-weight-medium text-decoration-none"
            :to="{ name: 'site-sections', params: { site_id: item.site_id, site_page_id: item.id } }"
          >
            {{ item.href }}
          </router-link>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{ item }">
          <div class="d-flex align-center justify-center">
            <!-- dropdown -->
            <v-menu
              bottom
              left
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="!item.reserved"
                  small
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon size="18">
                    {{ icons.mdiDotsVertical }}
                  </v-icon>
                </v-btn>
                <div v-else>
                  --
                </div>
              </template>

              <v-list>
                <v-list-item
                  v-if="!item.reserved"
                  link
                  @click="setAside(item)"
                >
                  <v-list-item-title>
                    <v-icon
                      size="18"
                      class="me-2"
                    >
                      {{ icons.mdiPencilOutline }}
                    </v-icon>
                    <span>Edit</span>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                  v-if="!item.reserved"
                  link
                  @click="deleteSitePage(item)"
                >
                  <v-list-item-title>
                    <v-icon
                      size="18"
                      class="me-2"
                    >
                      {{ icons.mdiDeleteOutline }}
                    </v-icon>
                    <span>Delete</span>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </template>
      </v-data-table>
    </v-card>

    <site-pages-aside
      v-if="aside"
      v-model="aside"
      :resource="resource"
      @changed="loadData(); aside = false"
    />

    <!-- <form-aside
      v-if="aside"
      v-model="aside"
      :resource="resource"
      @changed="
        loadCards()
        aside = false
      "
    ></form-aside> -->
  </div>
</template>

<script>
import {
mdiDeleteOutline, mdiDotsVertical,
mdiMenuDown,
mdiMenuUp,
mdiPencilOutline,
mdiPlus,
mdiRefresh,
} from '@mdi/js'

import ImportDialog from '@/modules/doctor/components/ImportDialog'
import useSites from '@/modules/site/views/useSites'
import { t } from '@/plugins/i18n'
import router from '@/router'
import { formatDate } from '@core/utils/filter'
import { onMounted, ref } from '@vue/composition-api'
import SitePagesAside from './site-pages-resource/SitePagesAside.vue'
import useSitePages from './useSitePages'

export default {
  components: {
    ImportDialog,
    SitePagesAside,
  },
  setup() {
    const {
      tableItems, tableColumns, tableTotal, options, filters, loading, loadData, deleteSitePage,
    } = useSitePages()
    const { sitesList, loadSites } = useSites()

    const breadcrumbs = ref([
      { text: 'Site Info', disabled: false, href: '/sites' },
      { text: 'Site Pages', disabled: true, href: `/sites/${router.currentRoute.params.site_id}/site-pages` },
    ])

    const actions = ['Edit']
    const aside = ref(false)
    const setAside = item => {
      aside.value = true
      resource.value = item
    }

    const resource = ref(null)

    // const categories = ref([])

    // const loadCategories = async () => {
    //   try {
    //     const {
    //       status,
    //       data: {
    //         data: { records },
    //       },
    //     } = await fetchDistributors()

    //     if (status === 200) {
    //       categories.value = records.map(record => {
    //         return { title: record.name.en, value: record.id }
    //       })
    //     }
    //   } catch (error) {
    //     console.log(error)
    //   }
    // }

    // loadCategories()

    onMounted(async () => {
      filters.site_id = parseInt(router.currentRoute.params.site_id)
      loadSites()
    })

    const singleSelect = ref(false)
    const headerprops = ref({
      'sort-icon': mdiMenuDown,
    })
    const showFilter = ref(true)

    return {
      loading,
      t,
      formatDate,
      icons: {
        mdiDotsVertical,
        mdiPencilOutline,
        mdiDeleteOutline,
        mdiMenuDown,
        mdiMenuUp,
        mdiRefresh,
        mdiPlus,
      },

      // categories,
      setAside,
      aside,
      resource,

      tableColumns,
      tableItems,
      tableTotal,
      options,
      filters,
      sitesList,
      loadSites,

      actions,

      loadData,
      deleteSitePage,
      singleSelect,
      headerprops,
      showFilter,
      breadcrumbs,
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
