import axios from '@axios'

const fetchSites = queryParams => axios.get('/admin/sites', { params: queryParams })
const fetchSite = id => axios.get(`/admin/sites/${id}`)
const updateSite = (id, data) => axios.put(`/admin/sites/${id}`, data)
const storeSite = data => axios.post('/admin/sites', data)

const fetchSitePages = queryParams => axios.get('/admin/site-pages', { params: queryParams })
const storeSitePage = data => axios.post('/admin/site-pages', data)
const updateSitePage = (id, data) => axios.put(`/admin/site-pages/${id}`, data)
const destroySitePage = id => axios.delete(`/admin/site-pages/${id}`)

const fetchSiteSections = queryParams => axios.get('/admin/site-sections', { params: queryParams })
const storeSiteSection = data => axios.post('/admin/site-sections', data)
const updateSiteSection = (id, data) => axios.put(`/admin/site-sections/${id}`, data)
const destroySiteSection = id => axios.delete(`/admin/site-sections/${id}`)
const replicateSiteSection = id => axios.post(`/admin/site-sections/${id}/replicate`)
const storeSiteSectionSyncDistributors = (id, data) => axios.post(`/admin/site-sections/${id}/distributors/sync`, data)

const fetchSiteItems = queryParams => axios.get('/admin/site-items', { params: queryParams })
const fetchSiteItemRules = queryParams => axios.get('/admin/site-items/element-rules', { params: queryParams })
const storeSiteItem = data => axios.post('/admin/site-items', data)
const updateSiteItem = (id, data) => axios.put(`/admin/site-items/${id}`, data)
const destroySiteItem = id => axios.delete(`/admin/site-items/${id}`)
const storeSiteItemSyncDistributors = (id, data) => axios.post(`/admin/site-items/${id}/distributors/sync`, data)
const replicateSiteItem = id => axios.post(`/admin/site-items/${id}/replicate`)

const fetchSiteAssets = queryParams => axios.get('/admin/site-assets', { params: queryParams })
const storeSiteAsset = data => axios.post('/admin/site-assets/photo', data)
const updateSiteImageAsset = (id, data) => axios.post(`/admin/site-assets/${id}/photo`, data)
const updateSiteAsset = (id, data) => axios.put(`/admin/site-assets/${id}`, data)
const destroySiteAsset = id => axios.delete(`/admin/site-assets/${id}`)

const fetchDictionaries = queryParams => axios.get('/admin/dictionaries', { params: queryParams })
const storeDictionary = data => axios.post('/admin/dictionaries', data)
const updateDictionary = (id, data) => axios.put(`/admin/dictionaries/${id}`, data)
const destroyDictionary = id => axios.delete(`/admin/dictionaries/${id}`)

const fetchAlternatives = queryParams => axios.get('/admin/dictionary-alternatives', { params: queryParams })
const storeAlternative = data => axios.post('/admin/dictionary-alternatives', data)
const updateAlternative = (id, data) => axios.put(`/admin/dictionary-alternatives/${id}`, data)
const destroyAlternative = id => axios.delete(`/admin/dictionary-alternatives/${id}`)

const storeDictionaryChild = data => axios.post('/admin/dictionary-children', data)

const fetchSettings = (siteId, queryParams) => axios.get(`/admin/sites/${siteId}/settings`, { params: queryParams })
const updateSetting = (siteId, id, data) => axios.put(`/admin/sites/${siteId}/settings/${id}`, data)
const storeSettingChild = (siteId, data) => axios.post(`/admin/sites/${siteId}/setting-children`, data)

// const destroySetting = id => axios.delete(`/admin/settings/${id}`)

const fetchDistributorEmailTemplates = queryParams => axios.get('/admin/email-templates', { params: queryParams })
const fetchDistributorEmailTemplate = id => axios.get(`/admin/email-templates/${id}`)
const updateDistributorEmailTemplate = (id, data) => axios.put(`/admin/email-templates/${id}`, data)
const storeDistributorEmailTemplate = data => axios.post('/admin/email-templates', data)
const deleteDistributorEmailTemplate = id => axios.delete(`/admin/email-templates/${id}`)
const updateDistributorEmailTemplateSchedules = (id, data) => axios.put(`/admin/email-templates/${id}/reminder-schedules`, data)

export {
  deleteDistributorEmailTemplate,
  destroyAlternative,
  destroyDictionary,
  destroySiteAsset,
  destroySiteItem,
  destroySitePage,
  destroySiteSection,
  fetchAlternatives,
  fetchDictionaries,
  fetchDistributorEmailTemplate,
  fetchDistributorEmailTemplates,
  fetchSettings,
  fetchSite,
  fetchSiteAssets,
  fetchSiteItemRules,
  fetchSiteItems,
  fetchSitePages,
  fetchSiteSections,
  fetchSites,
  replicateSiteItem,
  replicateSiteSection,
  storeAlternative,
  storeDictionary,
  storeDictionaryChild,
  storeDistributorEmailTemplate,
  storeSettingChild,
  storeSite,
  storeSiteAsset,
  storeSiteItem,
  storeSiteItemSyncDistributors,
  storeSitePage,
  storeSiteSection,
  storeSiteSectionSyncDistributors,
  updateAlternative,
  updateDictionary,
  updateDistributorEmailTemplate,
  updateDistributorEmailTemplateSchedules,
  updateSetting,
  updateSite,
  updateSiteAsset,
  updateSiteImageAsset,
  updateSiteItem,
  updateSitePage,
  updateSiteSection,
}
